import { MantineThemeOverride } from "@mantine/core";
import { colors } from "./colors";

export const mainTheme = ({
  color,
}: {
  color?: string;
}): MantineThemeOverride => ({
  components: {
    Text: {
      styles: {
        root: {
          color: "#000",
          lineHeight: "120%",
        },
      },
    },
    Title: {
      styles: {
        root: {
          color: colors.dark,
          lineHeight: "150%",
        },
      },
    },
    HoverCard: {
      styles: {
        dropdown: {
          border: "1px solid #eee",
        },
      },
    },
  },
});
